<template>
  <div class="item-sales-report">
    <v-card class="mb-2">
      <v-card-title>Laporan Penjualan Item</v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <date-range-picker v-model="filter.dateRange"></date-range-picker>

        <customers-autocomplete
          :value.sync="filter.customer"
          :useAll="true"
          class="mb-3"
        ></customers-autocomplete>
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-text-field
        v-model="filter.search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        filled
        hide-details
        @input="getData"
      ></v-text-field>

      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="count"
        class="elevation-1"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
import DateRangePicker from '@/components/Pickers/DateRangePicker.vue'
import CustomersAutocomplete from '@/components/CustomersAutocomplete'

export default {
  name: 'ItemSalesReport',
  components: { CustomersAutocomplete, DateRangePicker },
  data() {
    return {
      count: 0,
      filter: {
        customer: '',
        dateRange: null,
        search: null,
      },
      headers: [
        { text: 'Kode Item', value: 'stock_id' },
        { text: 'Item', value: 'description' },
        { text: 'Qty', value: 'quantity' },
        { text: 'Harga Satuan', value: 'unit_price' },
      ],
      items: [],
      loading: false,
      options: {},
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.getData()
      },
    },

    'filter.dateRange'() {
      this.getData()
    },
  },
  methods: {
    getData() {
      if (!this.filter.dateRange) return

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.loading = true
      this.$http
        .get('/inventory/items/sales', {
          params: {
            customer: this.filter.customer,
            startDate: this.filter.dateRange ? this.date2sql(this.filter.dateRange[0]) : '',
            endDate: this.filter.dateRange ? this.date2sql(this.filter.dateRange[1]) : '',
            sortBy: sortBy,
            sortDesc: sortDesc,
            page: page,
            itemsPerPage: itemsPerPage,
          },
        })
        .then(({ data }) => {
          this.count = data.data.count
          this.items = data.data.result
        })
        .catch((err) => {
          console.log(err)
        })
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>
